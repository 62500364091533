<!--
 * @Author: your name
 * @Date: 2021-05-11 15:24:59
 * @LastEditTime: 2023-03-23 14:58:15
 * @LastEditors: Pengyue Zhou 1291431328@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \CSMS-Web\src\views\login\index.vue
-->
<template>
  <div class="back-ground">
    <div class='back-ground-content'>
        <div class="bg-wrap"></div>
        <div class="login-box login">
          <div class="schoolName">
            <div>欢迎登录</div>
            <div>在线培训平台</div>
          </div>
          <div class="formBox">
            <el-form ref="form" :model="form" label-width="auto" inline-message>
              <el-form-item prop="username" class="inputBox">
                <div class="phone input-lable">手机号:</div>
                <el-input
                  v-model="form.username"
                  placeholder="请输入您的手机号"
                  size="medium"
                  id="user"
                  @change="loginMess = ''"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password" class="passwordBox">
                <div class="paw input-lable">密码:</div>
                <el-input
                  v-model="form.password"
                  placeholder="请输入密码"
                  size="medium"
                  type="password"
                  @change="loginMess = ''"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item prop="" class="passwordBox1">
                {{ loginMess }}
              </el-form-item>
              <el-form-item>
                <el-button
                  class="btnBox"
                  :loading="showLoginBtn"
                  @click="validateForm"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loginMess: '',
      showLoginBtn: false,
      captchaCodeUrl: '',
      form: {
        username: '',
        password: '',
        captcha: '',
        tenantId: 0,
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    }
  },
  methods: {
    validateForm () {
      // this.$router.push('/overview')
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        } else {
          this.loginIn()
        }
      })
    },
    // 点击登录接口
    loginIn () {
      let data = {
        username: this.form.username,
        password: this.form.password,
        captcha: '',
        tenantId: 0,
      }
      console.log(data, 'datadatadatadatadatadata')
      if (this.form.username.length <= 0) {
        this.loginMess = '请输入用户名'
      } else if (this.form.password.length <= 0) {
        this.loginMess = '请输入密码'
      } else {
        this.showLoginBtn = true
        this.$axios
          .post(this.$apiUrl.loginIn, data)
          .then((response) => {
            let res = response.data
            if (res.success) {
              console.log(res.data)
              let { userInfo, token, studentInfo } = res.data
              localStorage.setItem('token', token)
              localStorage.setItem('userInfo', JSON.stringify(userInfo))
              // studentInfo
              localStorage.setItem('studentInfo', JSON.stringify(studentInfo))
              // 登录成功
              this.$router.push('/home')
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              })
            }
            this.showLoginBtn = false
          })
          .catch((error) => {
            if (error.status == 500) {
              this.loginMess = '登录超时,请稍后重试！'
            }
            this.showLoginBtn = false
          })
      }
    },
    enterLogin (e) {
      if (e.keyCode === 13) {
        this.validateForm()
      }
    },
  },
  mounted () {
    let inputelement = document.getElementById('user')
    inputelement.focus()
    window.addEventListener('keyup', this.enterLogin)
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')
    // studentInfo
    localStorage.removeItem('studentInfo')
  },
  destroyed () {
    window.removeEventListener('keyup', this.enterLogin)
  },
}
</script>

<style scoped lang="scss">
.back-ground {
  width: 100vw;
  height: 100vh;
  padding: 0;
  z-index: -10;
  zoom: 1;
  // background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0px;
  .back-ground-content{
    height: 100%;
    width: 100%;
    display: flex;
  }
  .bg-wrap {
    width: 50%;
    height: 100%;
    background: url("../../assets/login_left.png") no-repeat;
    background-size: 100% 100%;
  }
  .login-box {
  flex: 1;
    padding-left: 113px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;
  }
  .login {
    .schoolName {
      font-size: 56px;
      font-weight: 400;
      color: #4774df;
      margin-bottom: 84px;
    }
    .formBox {
      width: 487px;
      .input-lable {
        font-size: 30px;
        color: #323851;
        margin-bottom: 13px;
      }
      .inputBox {
        margin-bottom: 27px;
      }
      ::v-deep.el-input__inner {
        font-size: 24px;
        border: none;
        height: 70px;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #cecece;
        background-color: #fff !important;
      }
      ::v-deep .el-input--medium {
        .el-input__icon {
          /* line-height: 70px; */
        }
      }
      .passwordBox {
        margin-bottom: 27px;
      }
      .passwordBox1 {
        margin-bottom: 0;
        height: 41px;
        line-height: 41px;
        color: #0095d5;
      }
      .btnBox {
        width: 487px;
        height: 70px;
        background: #4774df;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        font-size: 30px;
        color: #ffffff;
      }
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }
}
</style>
